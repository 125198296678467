var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-wrapper" },
    [
      _c("PageTitle", { attrs: { text: "Profil" } }),
      _c("ProfileBanner", {
        attrs: { firstname: _vm.user.firstname, lastname: _vm.user.lastname }
      }),
      _c(
        "v-list",
        { staticClass: "linksList mb-md-12" },
        [
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToPointsHistory()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Historique des points")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-large": "", color: "homeglobal_picto" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToOrders()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Commandes")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-large": "", color: "homeglobal_picto" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.compShowCashback && _vm.compIsThereTransactions
            ? _c(
                "v-list-item",
                {
                  staticClass: "listItem",
                  on: {
                    click: function($event) {
                      return _vm.navigateToCashback()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "listItemText" }, [
                        _vm._v("Cashback")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "homeglobal_picto" } },
                        [_vm._v(" fas fa-angle-right ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              attrs: { "data-cy": "scale-point-button" },
              on: {
                click: function($event) {
                  return _vm.navigateToPointsScale()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Barème de points")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-large": "", color: "homeglobal_picto" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToDocumentation()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Confidentialité")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-large": "", color: "homeglobal_picto" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isThereAppToSynchronize && _vm.showApplicationsItem
            ? [
                _c(
                  "v-list-item",
                  {
                    staticClass: "listItem",
                    on: { click: _vm.navigateToApplications }
                  },
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "listItemText" },
                          [_vm._v("Vos applications")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { "x-large": "", color: "homeglobal_picto" }
                          },
                          [_vm._v(" fas fa-angle-right ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.showPasswordLink
            ? _c(
                "v-list-item",
                {
                  staticClass: "listItem",
                  on: {
                    click: function($event) {
                      return _vm.navigateToPassword()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "listItemText" }, [
                        _vm._v("Modifier mon mot de passe")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "homeglobal_picto" } },
                        [_vm._v(" fas fa-angle-right ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToHelp()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Besoin d'aide ?")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-large": "", color: "homeglobal_picto" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }