



















































































































































































































































































import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import * as common from '@/services/common/common';
import SignInButton from './Button/SignInButton.vue';
import SignUpButton from './Button/SignUpButton.vue';
import ExpirableBalance from '@/components/ExpirableBalance.vue';
import { SSOBehavior } from '@/services/merchantSpace/types';
import * as UserService from '@/services/userSpace/UserService';

interface Route {
  name: string;
  path: string;
  icon: string;
  connected: boolean;
  dataCy: string;
}

interface DisplayHeader {
  custom: boolean;
  text: string;
  icon: string;
}

@Component({
  components: { SignInButton, ExpirableBalance, SignUpButton },
})
export default class Header extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  logo!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  showLogoOrPreviousBtn!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  showLogoutBtn!: boolean;

  textPoints = '';

  showDialog: boolean = false;

  dialogs: any[] = [{ name: 'Connexion', icon: 'fas fa-fingerprint' }];

  displayedHeader: DisplayHeader = { custom: false, icon: 'fas fa-angle-left', text: '' };

  backToDashboard: boolean = false;

  filteredRoutes: Route[] = [];

  expirableBalance: number = 0;

  nextPeremptionDate: any = [];

  customHeaderRoutes: any[] = [
    { path: /^\/dashboard\/profile\/$/, textToDisplay: 'Mon profil', backToDashboard: true },
    {
      path: /^\/dashboard\/profile\/update$/,
      textToDisplay: 'Infos Personnelles',
      backToDashboard: false,
    },
    { path: /^\/help$/, textToDisplay: "Besoin d'aide", backToDashboard: false },
    {
      path: /^\/dashboard\/profile\/updatePassword$/,
      textToDisplay: 'Mot de passe',
      backToDashboard: false,
    },
    { path: /^\/shop$/, textToDisplay: 'Boutique', backToDashboard: true },
    { path: /^\/shop\/(-?\d)$/, textToDisplay: 'Boutique', backToDashboard: false },
    { path: /^\/dashboard\/profile\/orders$/, textToDisplay: 'Commandes', backToDashboard: false },
    { path: /^\/dashboard\/profile\/history$/, textToDisplay: 'Historique', backToDashboard: false },
    {
      path: /^\/dashboard\/profile\/documentation\/$/,
      textToDisplay: 'Confidentialité',
      backToDashboard: false,
    },
    {
      path: /^\/dashboard\/profile\/documentation\/gcu$/,
      textToDisplay: 'ML et CGU',
      backToDashboard: false,
    },
    {
      path: /^\/dashboard\/profile\/documentation\/regulation$/,
      textToDisplay: 'Parcours utilisateurs',
      backToDashboard: false,
    },
    {
      path: /^\/dashboard\/profile\/documentation\/privacyPolicy$/,
      textToDisplay: 'Politique de conf.',
      backToDashboard: false,
    },
    {
      path: /^\/dashboard\/profile\/documentation\/deleteAccount$/,
      textToDisplay: 'Supprimer mon compte',
      backToDashboard: false,
    },
    {
      path: /^\/dashboard\/profile\/referFriend$/,
      textToDisplay: 'Parrainer un ami',
      backToDashboard: false,
    },
    { path: /^\/dashboard\/profile\/cashback$/, textToDisplay: 'Cashback', backToDashboard: false },
    {
      path: /^\/dashboard\/profile\/applications(\/?)(.*)$/,
      textToDisplay: 'Applications',
      backToDashboard: false,
    },
  ];

  async created() {
    if (this.hasActivePeremption && this.connected) {
      this.nextPeremptionDate = UserService.getNextPeremptionDate(this.getFunctionality("EXPIRATION_POINTS"));
      this.expirableBalance = await UserService.getUserPeremptionBalance(new Date(this.nextPeremptionDate));
    }
  }

  async mounted() {
    this.shouldDisplaySignIn();
  }

  @Watch('$route', { immediate: true, deep: true })
  async onRouteChange() {
    const customHeaderRoute = this.customHeaderRoutes.find((route) => route.path.test(this.$route.path));
    this.displayedHeader.custom = false;
    this.backToDashboard = false;
    if (this.connected) {
      if (customHeaderRoute) {
        this.displayedHeader.custom = true;
        this.displayedHeader.text = customHeaderRoute.textToDisplay;
        this.backToDashboard = customHeaderRoute.backToDashboard;
      }
    }
  }

  routes: Route[] = [
    {
      name: 'Boutique',
      path: '/shop',
      icon: 'fas fa-shopping-bag',
      connected: false,
      dataCy: 'open-shop-btn',
    },
    {
      name: 'Tableau de bord',
      path: '/dashboard',
      icon: 'fas fa-grip-horizontal',
      connected: true,
      dataCy: 'open-tdb-btn',
    },
    {
      name: 'Boutique',
      path: '/shop',
      icon: 'fas fa-shopping-bag',
      connected: true,
      dataCy: 'open-shop-btn',
    },
  ];

  get connected(): boolean {
    return common.isConnected();
  }

  get textPoint() {
    if (this.getPoints < 1) {
      this.textPoints = 'point';
    } else {
      this.textPoints = 'points';
    }
    return this.textPoints;
  }

  get ssoBehavior(): SSOBehavior {
    return this.$store.getters['platform/ssoBehavior'];
  }

  get getLogo() {
    return this.logo ? `${process.env.VUE_APP_API_URL}/api${this.logo}` : require('@/assets/logo.svg');
  }

  get routesToDisplay() {
    this.filteredRoutes = this.routes.filter(({ connected }) => connected === this.connected);

    return this.filteredRoutes;
  }

  get version(): string {
    return process.env.VUE_APP_VERSION;
  }

  get getPoints() {
    if (common.isConnected()) {
      return this.$store.getters['user/getUserPoints'];
    }
    return 0;
  }

  get showVersion() {
    const devLocation = /integ|preprod|localhost/;
    return devLocation.test(window.location.hostname);
  }

  get activeFunctionalities() {
    return this.$store.getters['platform/getFunctionalities'].filter(({ isActive }) => isActive);
  }

  get hasActivePeremption() {
    return this.$store.getters['platform/peremptionIsActive'];
  }

  get showNextPeremption() {
    return this.hasActivePeremption && this.expirableBalance > 0;
  }

  formatDate(date: any) {
    return date.toLocaleDateString('fr-FR', 
      { 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric' 
      });
  }

  shouldDisplaySignIn() {
    const { signInIsDiplayed } = this.$route.query;

    if (signInIsDiplayed && !this.connected) {
      (this.$refs.signInBtn as HTMLElement).click();
      this.$router.replace({ query: {} });
    }
  }

  get signUpIsShow(): boolean {
    if (this.ssoBehavior === SSOBehavior.NONE) {
      return true;
    }

    return this.$store.getters['platform/signUpIsShow'];
  }

  getFunctionality(slug: string) {
    return this.activeFunctionalities.find((f) => f.functionality.slug === slug);
  }

  handleClick(): void {
    const pageToShow = this.$store.getters['platform/getPlatform'].config.landingPath;

    // boolean => check if shop as homepage option is active
    const shopAsLandingPath = this.$store.getters['platform/homeToShopIsActive'];

    // Redirect user to the home screen if accessToken is null(if not authenticated)
    // if shop as homepage option is not active => redirect home
    if (this.$route.path !== '/' && !this.connected && !shopAsLandingPath) {
      this.$router.push('/');
      return;
    }

    // if shop as homepage option is active => redirect shop
    if (this.$route.path !== '/shop' && !this.connected && shopAsLandingPath) {
      this.$router.push('/shop');
      return;
    }

    // if user connected => redirect landingPath
    if (this.connected) {
      this.$router.push(pageToShow);
    }
  }

  handleReturn(): void {
    if (this.backToDashboard) {
      this.$router.push('/dashboard');
    } else {
      this.$router.back();
    }
  }

  @Emit('closeSignin')
  closeDialog(): void {
    this.showDialog = false;
    this.onDialogChange(this.showDialog);
  }

  async logout() {
    await this.$emit('is-logout');
    await this.$store.dispatch('user/resetTokens');
    localStorage.removeItem('synchroString');
  }

  @Emit('onDialogChange')
  onDialogChange(showDialog: boolean): void {}
}
