import { apiAuthenticatedInstance, apiPublicInstance } from '@/services/API';

interface FontUpload {
  name: string;
  url: string;
}

interface CalendarStyle {
  backgroundUrl: string;
  titleColor: string;
  textColor: string;
  titleFont: FontUpload;
  textFont: FontUpload;
}

interface CalendarBanner {
  description: string;
  style: {
    backgroundUrl: string;
    textColor: string;
    button: {
      textColor: string;
      backgroundColor: string;
      textFont: FontUpload;
    };
    textFont: FontUpload;
  };
}

export interface Calendar {
  id: number;
  title: string;
  description: string;
  style: CalendarStyle;
  calendarDays: any[];
  calendarBanner: CalendarBanner;
  startAt: string;
  endAt: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CalendarDay {
  id: number;
  availableAt: Date;
  offer: any;
  openedDayList: any[];
}

export function findMyCalendar() {
  return apiAuthenticatedInstance()
    .get(`/api/calendars/current/mine`)
    .then((res) => res.data);
}

export function findRunningPublicCalendar({ platformId }: { platformId: number }) {
  return apiPublicInstance()
    .get(`/api/calendars/current/public?platformId=${platformId}`)
    .then((res) => res.data);
}

export function openDay(calendarId: Calendar['id'], { calendarDayId }: { calendarDayId: CalendarDay['id'] }) {
  return apiAuthenticatedInstance()
    .post(`/api/calendars/${calendarId}/open_day`, {
      calendarDayId,
    })
    .then((res) => res.data);
}
