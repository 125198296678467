var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "header px-md-12",
      attrs: {
        app: "",
        fixed: "",
        dense: "",
        color: "header_fond",
        "data-cy": "header-app"
      }
    },
    [
      _vm.showLogoOrPreviousBtn
        ? [
            _vm.$vuetify.breakpoint.mdAndUp ||
            _vm.displayedHeader.custom === false
              ? [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center header-logo" },
                    [
                      _c("img", {
                        staticClass: "logo",
                        attrs: { src: _vm.getLogo, alt: "logo" },
                        on: { click: _vm.handleClick }
                      })
                    ]
                  ),
                  _vm.showVersion
                    ? _c("div", { staticClass: "version" }, [
                        _vm._v(" version "),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.version) + " ")
                      ])
                    : _vm._e()
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center menu-return" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "returnButton",
                          attrs: { large: "" },
                          on: { click: _vm.handleReturn }
                        },
                        [_vm._v(" " + _vm._s(_vm.displayedHeader.icon) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "header-title ml-md-4 justify-md-start" },
                    [_vm._v(_vm._s(_vm.displayedHeader.text))]
                  )
                ]
          ]
        : _vm._e(),
      [
        _c("nav", { staticClass: "main-nav d-md-block" }, [
          _c(
            "ul",
            { staticClass: "main-nav__list" },
            [
              !_vm.connected
                ? [
                    _c(
                      "v-list-item",
                      {
                        staticClass:
                          "menu-item-content-list-item main-nav__list-item px-1",
                        attrs: {
                          "data-cy": "header-connection-button",
                          exact: ""
                        }
                      },
                      [
                        _c("SignInButton", {
                          ref: "signInBtn",
                          staticClass: "menu-item-content-list-item-title",
                          attrs: { plain: "", elevation: "0" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { small: "" }
                                      },
                                      [_vm._v(" fas fa-fingerprint ")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1731355748
                          )
                        })
                      ],
                      1
                    ),
                    _vm.signUpIsShow
                      ? _c(
                          "v-list-item",
                          {
                            staticClass:
                              "menu-item-content-list-item main-nav__list-item px-1",
                            attrs: {
                              "data-cy": "header-connection-button",
                              exact: ""
                            }
                          },
                          [
                            _c("SignUpButton", {
                              staticClass: "menu-item-content-list-item-title",
                              attrs: {
                                plain: "",
                                elevation: "0",
                                "data-cy": "open-signup-btn"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "icon",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-4",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v(" fas fa-user-plus ")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                4051934434
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.connected
                ? [
                    _vm.showNextPeremption &&
                    _vm.expirableBalance > 0 &&
                    _vm.nextPeremptionDate
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: "",
                              "content-class": "main-nav__tooltip"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-list-item",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "menu-item-content-list-item main-nav__list-item",
                                              attrs: {
                                                "data-cy": "header-points",
                                                exact: "",
                                                to: { name: "history" }
                                              }
                                            },
                                            "v-list-item",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "menu-item-content-list-item-title-points points"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.getPoints) +
                                                  " " +
                                                  _vm._s(_vm.textPoint) +
                                                  " "
                                              ),
                                              _vm.expirableBalance > 0
                                                ? _c("span", {
                                                    staticClass: "red-dot"
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              688953526
                            )
                          },
                          [
                            _c("ExpirableBalance", {
                              staticClass: "expirable-balance",
                              attrs: {
                                expirableBalance: _vm.expirableBalance,
                                nextPeremptionDate: _vm.formatDate(
                                  _vm.nextPeremptionDate
                                )
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "v-list-item",
                          {
                            staticClass:
                              "menu-item-content-list-item main-nav__list-item",
                            attrs: {
                              "data-cy": "header-points",
                              exact: "",
                              to: { name: "history" }
                            }
                          },
                          [
                            _c(
                              "v-list-item-title",
                              {
                                staticClass:
                                  "menu-item-content-list-item-title-points points"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getPoints) +
                                    " " +
                                    _vm._s(_vm.textPoint) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                  ]
                : _vm._e(),
              _vm._l(_vm.filteredRoutes, function(route) {
                return _c(
                  "v-list-item",
                  {
                    key: route.name,
                    staticClass:
                      "menu-item-content-list-item main-nav__list-item",
                    attrs: {
                      "data-cy": route.dataCy,
                      exact: "",
                      to: route.path
                    }
                  },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "menu-item-content-list-item-title" },
                      [_vm._v(" " + _vm._s(route.name) + " ")]
                    ),
                    _c(
                      "v-list-item-icon",
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "menu-item-content-list-item-icon",
                            attrs: { small: "" }
                          },
                          [_vm._v(" " + _vm._s(route.icon) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.showLogoutBtn && _vm.connected
                ? _c(
                    "v-list-item",
                    {
                      staticClass:
                        "menu-item-content-list-item main-nav__list-item",
                      attrs: { "data-cy": "disconnect-button", exact: "" },
                      on: { click: _vm.logout }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "menu-item-content-list-item-title" },
                        [_vm._v(" Déconnexion ")]
                      ),
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "menu-item-content-list-item-icon",
                              attrs: { small: "" }
                            },
                            [_vm._v(" fas fa-power-off ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ],
      _c("v-spacer"),
      [
        _c(
          "v-menu",
          {
            attrs: { "content-class": "menu-item-content d-md-none" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "accent--text menu-button d-md-none",
                          attrs: { icon: "", plain: "", ripple: false }
                        },
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { medium: "" } }, [
                          _vm._v("fas fa-ellipsis-v")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "v-list",
              { staticClass: "menu-item-content-list" },
              [
                !_vm.connected
                  ? _c(
                      "v-list-item",
                      {
                        staticClass: "menu-item-content-list-item",
                        on: {
                          click: function($event) {
                            return _vm.$refs.signInMobile.click()
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "menu-item-content-list-item-title" },
                          [
                            _c("SignInButton", {
                              ref: "signInMobile",
                              staticClass:
                                "menu-item-content-list-item-title pa-0",
                              attrs: {
                                ripple: false,
                                plain: "",
                                elevation: "0"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-icon",
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "menu-item-content-list-item-icon",
                                attrs: { small: "" }
                              },
                              [_vm._v(" fas fa-fingerprint ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.connected && _vm.signUpIsShow
                  ? _c(
                      "v-list-item",
                      {
                        staticClass: "menu-item-content-list-item",
                        on: {
                          click: function($event) {
                            return _vm.$refs.signUpMobile.click()
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "menu-item-content-list-item-title" },
                          [
                            _c("SignUpButton", {
                              ref: "signUpMobile",
                              staticClass:
                                "menu-item-content-list-item-title pa-0",
                              attrs: {
                                ripple: false,
                                plain: "",
                                elevation: "0",
                                "data-cy": "open-signup-btn"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-icon",
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "menu-item-content-list-item-icon",
                                attrs: { small: "" }
                              },
                              [_vm._v(" fas fa-user-plus ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.routesToDisplay, function(route) {
                  return _c(
                    "v-list-item",
                    {
                      key: route.name,
                      staticClass: "menu-item-content-list-item",
                      attrs: { to: route.path }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "menu-item-content-list-item-title" },
                        [_vm._v(" " + _vm._s(route.name) + " ")]
                      ),
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "menu-item-content-list-item-icon",
                              attrs: { small: "" }
                            },
                            [_vm._v(" " + _vm._s(route.icon) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                _vm.showLogoutBtn && _vm.connected
                  ? _c(
                      "v-list-item",
                      {
                        staticClass: "menu-item-content-list-item",
                        on: { click: _vm.logout }
                      },
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "menu-item-content-list-item-title" },
                          [_vm._v(" Déconnexion ")]
                        ),
                        _c(
                          "v-list-item-icon",
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "menu-item-content-list-item-icon",
                                attrs: { small: "" }
                              },
                              [_vm._v(" fas fa-power-off ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }