var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("PageTitle", { attrs: { text: "Boutique" } }),
      _c(
        "div",
        { staticClass: "details-top" },
        [
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "div",
              { staticClass: "details-top-photos" },
              [
                _c("CarouselCard", {
                  staticClass: "offer-details-carousel",
                  attrs: { pictures: _vm.compPreviewsPhoto, big: "" }
                }),
                _c("div", { staticClass: "details-top-photos-picto" }, [
                  _vm.compTargetAudience
                    ? _c(
                        "div",
                        { staticClass: "details-top-photos-picto-svg" },
                        [_c("SvgPictoPro")],
                        1
                      )
                    : _vm._e(),
                  _vm.isLuckOffer
                    ? _c(
                        "div",
                        { staticClass: "details-top-photos-picto-svg" },
                        [_c("SvgPictoLuck")],
                        1
                      )
                    : _vm._e(),
                  _vm.isCashbackOffer
                    ? _c(
                        "div",
                        { staticClass: "details-top-photos-picto-svg" },
                        [_c("SvgPictoCashback")],
                        1
                      )
                    : _vm._e(),
                  _vm.isShowcaseOffer
                    ? _c(
                        "div",
                        { staticClass: "details-top-photos-picto-svg" },
                        [_c("SvgPictoShowcase")],
                        1
                      )
                    : _vm._e(),
                  _vm.isFundingOffer
                    ? _c(
                        "div",
                        { staticClass: "card-offer-type__svg" },
                        [_c("SvgPictoCollect")],
                        1
                      )
                    : _vm._e()
                ]),
                _vm.isLuck
                  ? _c("Countdown", {
                      staticClass: "details-top-countdown",
                      attrs: { drawAt: _vm.offer.drawAt }
                    })
                  : _vm._e(),
                _vm.isLuck
                  ? _c("OrdersNumber", {
                      staticClass: "details-top-orders-number",
                      attrs: {
                        offerId: _vm.offer.id,
                        "platform-id": _vm.platformId
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-card",
                { staticClass: "details-top-description" },
                [
                  _c(
                    "v-card",
                    { staticClass: "details-top-description-container" },
                    [
                      _c("v-card-title", [
                        _c(
                          "div",
                          {
                            staticClass: "details-top-description-title-title",
                            attrs: {
                              "data-cy": "offer-details-offer-companyName"
                            }
                          },
                          [
                            _c("p", [
                              _vm._v(" " + _vm._s(_vm.offer.companyName) + " ")
                            ]),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "details-top-description-title-title__subtitle",
                                attrs: {
                                  "data-cy": "offer-details-offer-title"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.offer.title) + " ")]
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "details-top-description-content" },
                        [
                          _c("span", {
                            staticClass:
                              "details-top-description-content__html",
                            attrs: {
                              "data-cy": "offer-details-offer-description"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.offer.description)
                            }
                          })
                        ]
                      ),
                      _vm.isFundingOffer
                        ? _c(
                            "div",
                            [
                              _vm.offer.maxPoints
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "details-top-description-points-objectif",
                                      attrs: {
                                        "data-cy": "offer-details-offer-points"
                                      }
                                    },
                                    [
                                      _vm._v(" Objectif : "),
                                      _c("i18n-n", {
                                        attrs: {
                                          tag: "span",
                                          value: _vm.offer.maxPoints,
                                          format:
                                            _vm.offer.maxPoints.toString()
                                              .length <= 6
                                              ? "decimal"
                                              : "decimal_notation"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tc(
                                              "global.abbreviated_points",
                                              _vm.offer.maxPoints
                                            )
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-progress-linear", {
                                staticClass:
                                  "details-top-description-progressBar",
                                attrs: {
                                  rounded: "",
                                  height: "1rem",
                                  "background-color":
                                    "shopoffre_texte lighten-5",
                                  color: "shopoffre_fond_bouton lighten-1",
                                  value: _vm.progressBarValue
                                }
                              }),
                              typeof _vm.offer.filledPoints === "number"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "details-top-description-points-acquired"
                                    },
                                    [
                                      _vm._v(" Déjà récolté : "),
                                      _c("i18n-n", {
                                        attrs: {
                                          tag: "span",
                                          value: _vm.offer.filledPoints,
                                          format:
                                            _vm.offer.filledPoints.toString()
                                              .length <= 6
                                              ? "decimal"
                                              : "decimal_notation"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tc(
                                              "global.abbreviated_points",
                                              _vm.offer.filledPoints
                                            )
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isShowcaseOffer & !_vm.isFundingOffer
                        ? _c(
                            "div",
                            {
                              staticClass: "details-top-description-points",
                              attrs: { "data-cy": "offer-details-offer-points" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.offer.points) +
                                  " " +
                                  _vm._s(_vm.textPoint) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isConnected
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "details-top-description-button",
                              attrs: {
                                large: "",
                                rounded: "",
                                color: "shopoffre_fond_bouton",
                                "data-cy": "offer-details-offer-button"
                              },
                              on: { click: _vm.onBuyClick }
                            },
                            [_vm._v(" " + _vm._s(_vm.compTextForBtn) + " ")]
                          )
                        : _c(
                            "SignInButton",
                            {
                              staticClass: "details-top-description-button",
                              attrs: {
                                forceRedirectTo: {
                                  name: "offerDetail",
                                  params: {
                                    offerId: _vm.offer && _vm.offer.id
                                  }
                                },
                                large: "",
                                rounded: "",
                                color: "shopoffre_fond_bouton",
                                "data-cy": "offer-details-offer-button"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.compTextForBtn) + " ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "details-bottom",
          attrs: { "data-cy": "offer-details-offer-details" }
        },
        [
          _c("v-row", [_c("v-col", [_vm._v("Détails de l'offre:")])], 1),
          !_vm.isShowcaseOffer
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-icon", { staticClass: "icon" }, [
                        _vm._v("fas fa-calendar-alt")
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.compTextForDate))])
                    ],
                    1
                  ),
                  !_vm.isFundingOffer
                    ? _c(
                        "v-col",
                        [
                          _c("v-icon", { staticClass: "icon" }, [
                            _vm._v(_vm._s(_vm.compIconForDelivery))
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.compTextForDelivery))])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              !_vm.isShowcaseOffer & !_vm.isFundingOffer
                ? _c(
                    "v-col",
                    [
                      _c("v-icon", { staticClass: "icon" }, [
                        _vm._v("fas fa-shopping-bag")
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.compTextForStock) + " ")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.compUrlWebsite
                ? _c(
                    "v-col",
                    [
                      _c("v-icon", { staticClass: "icon" }, [
                        _vm._v("fas fa-globe")
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "website-url",
                          attrs: {
                            href: "//" + _vm.compUrlWebsite,
                            target: "_blank"
                          }
                        },
                        [_vm._v(" Voir le site ")]
                      ),
                      _c("v-icon", {
                        attrs: { small: "" },
                        domProps: {
                          textContent: _vm._s("fas fa-chevron-right")
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.compUrlWebsite ? _c("v-spacer") : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }