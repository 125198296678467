


































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { formRules } from '@/services/userSpace/UserService';
import { createUser } from '@/api/resources/ssoApi';
import { ServiceKey } from '@/constants';
import { getTranslation } from '@/i18n';
import SSOPartnerErrorDialog from '../Dialogs/SSOPartnerErrorDialog.vue';
import { loginAndRedirectToLanding } from '@/services/authSpace/sso.service';
import { getUIConfig } from '@/configs';
import SSOPartnerSynchronizeDialog from '@/components/Dialogs/SSOPartnerSynchronizeDialog.vue';
import AskSSOSynchroDialog from '@/components/Dialogs/AskSSOSynchroDialog.vue'; // EXTERNAL_AND_DOB_EXCEPTIONS
import { getApiErrorCode } from '@/api/utils';

// EXTERNAL_AND_DOB_EXCEPTIONS
const PAIRING_NOT_FOUND_EXCEPTION = 'PairingNotFoundException';
const CONFLICT = 'Conflict';
const NON_UNIQUE_EXTERNAL_ACCOUNT_ID = 'NonUniqueExternalAccountID';

// EXTERNAL_EXCEPTIONS
const CARD_FORMAT_EXCEPTION = 'CardFormatException';
const IRIGO_USER_NOT_FOUND = 'IrigoUserNotFound';
const IRIGO_MTICKET_USER_NOT_FOUND = 'IrigoMTicketUserNotFound';
const BIBUS_MTICKET_USER_NOT_FOUND = 'BibusMTicketUserNotFound';
const KORRIGO_USER_NOT_FOUND = 'KorrigoUserNotFound';
const EXTERNAL_API_DOWN = 'ErrorContactingExternalAPI';
const CONFLICT_ASTUCE = 'AstuceExternalAccountIdConflict';
const TBM_UNAUTHORIZED_EXCEPTION = 'UnauthorizedTBMApiException';
const TBM_PAIRING_EXCEPTION = 'TBMPairingException';
const TBM_MTICKET_PAIRING_EXCEPTION = 'TBMMticketPairingException';
const TBM_CUSTOMER_NOT_FOUND_EXCEPTION = 'TBMCustomerNotFoundException';
const TBM_SVD_DIFFERENCE_DETECTED_EXCEPTION = 'TBMSVDDifferenceDetectedException';
const TBM_SVD_CUSTOMER_NOT_FOUND = 'TBMSVDCustomerNotFound';
const TBM_CONFLICT_EXCEPTION = 'TBMConflictException';
const MODALIS_CONFLICT_EXCEPTION = 'ModalisConflictException';

const PARTNER_UPDATE_EXCEPTION = 'PartnerUpdateException';
const EXTERNAL_AND_DOB_EXCEPTIONS = [PAIRING_NOT_FOUND_EXCEPTION, CONFLICT, NON_UNIQUE_EXTERNAL_ACCOUNT_ID];
const EXTERNAL_EXCEPTIONS = [CARD_FORMAT_EXCEPTION];
const CLIENT_EXCEPTIONS = [PARTNER_UPDATE_EXCEPTION];

interface Tooltip {
  mainDesc: string;
  imageSrc: string;
  optionalDesc: string;
}

@Component({
  components: { AskSSOSynchroDialog, SSOPartnerSynchronizeDialog, SSOPartnerErrorDialog },
  beforeRouteEnter(to, from, next) {
    if (!to.query.userInfos) {
      next({ name: 'sso:signin' });
    } else {
      next();
    }
  },
})
export default class SSOPairingForm extends Vue {
  @Prop({ type: String, required: true }) userInfos!: string;

  @Prop({ type: String, required: false }) externalAccountIdFromSSO!: string;

  @Prop({ type: String, required: false }) externalAccessToken!: string;

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  relatedTicketings!: ('MTICKET' | 'SVD')[];

  formRules!: typeof formRules;

  form = {
    referrerCode: '',
    dateOfBirth: undefined,
    service: undefined,
    newsletterIsAccepted: false,
    cguValidated: false,
    externalAccountId: undefined,
  };

  submittedForm: any = null;

  state: 'signup' | 'need-external-account' = 'signup';

  submitting: boolean = false;

  errorCode: string = '';

  showDialogError: boolean = false;

  userInput: any = {};

  cardTooltip: Tooltip = {
    mainDesc: '',
    imageSrc: '',
    optionalDesc: '',
  };

  showCardTooltip: boolean = false;

  // ===== lifecycle =======
  created() {
    this.formRules = formRules;

    const [firstService] = this.sortedServices;
    this.form.service = firstService;
    this.cardTooltip.mainDesc = this.platform.style.signup.cardTooltipMainDesc;
    this.cardTooltip.optionalDesc = this.platform.style.signup.cardTooltipOptionalDesc;
    this.cardTooltip.imageSrc = this.platform.style.signup.cardTooltipImageUrl;

    this.$watch(
      ((vm) => [vm.form.service, vm.errorCode]) as any,
      () => this.determineState(),
      {immediate: true}
    );
  }

  @Watch('form.service')
  resetExternalAccountId() {
    this.form.externalAccountId = undefined;
  }

  @Watch('errorCode')
  onErrorCodeChange() {
    this.showDialogError = this.errorCode === CONFLICT_ASTUCE;
  }

  // ===== watchers =======
  // TODO: If form services really differs that much in the future, remove determineState and the watchers
  // to use KeepAlive component
  determineState() {
    const service = this.form.service as any;
    if (!service) {
      return;
    }

    const stateByService: Record<
      ServiceKey.IRIGO_ATOUT | ServiceKey.MODALIS | ServiceKey.TBM | ServiceKey.AVANTAG | ServiceKey.KORRIGO_BREST | ServiceKey.TBM_MTICKET | ServiceKey.IZILO,
      () => void
    > = {
      [ServiceKey.IRIGO_ATOUT]: () => {
        this.state = 'need-external-account';
      },
      [ServiceKey.MODALIS]: () => {
        this.state = 'need-external-account';
      },
      [ServiceKey.TBM]: () => {
        if (!this.relatedTicketings.includes('SVD')) {
          this.state = 'need-external-account';
        } else {
          this.state = 'signup';
        }
      },
      [ServiceKey.TBM_MTICKET]: () => {
        this.state = 'signup';
      },
      [ServiceKey.AVANTAG]: () => {
        this.state = 'need-external-account';
      },
      [ServiceKey.KORRIGO_BREST]: () => {
        this.state = 'need-external-account';
      },
      [ServiceKey.IZILO]: () => {
        this.state = 'need-external-account';
      },
    };

    if (stateByService[service.key]) {
      stateByService[service.key]();
    } else {
      this.state = 'signup';
    }
  }

  @Watch('form.service')
  onServiceChange() {
    this.errorCode = '';
  }

  // ===== computed =======
  get ssoOptions() {
    return this.$store.getters['platform/ssoOptions'];
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get platformName() {
    return this.platform.name;
  }

  get sortedServices() {
    return this.platform.platformService
      .filter((platformService) => platformService.forSignup)
      .sort((a, z) => a.rank - z.rank)
      .map((service) => service.service);
  }

  get hasServiceToSelect() {
    return this.sortedServices.length > 1;
  }

  get newsletterLabel() {
    return getTranslation(`${this.platform.key}.signUpForm.newsletterLabel`) || getTranslation(`signUpForm.newsletterLabel`);
  }

  get needExternalAccountField() {
    const service = this.form.service as any;
    const targetedKey = service.key; // it can be undefined, it doesnt matter

    return {
      label: getTranslation(`${targetedKey}.signUpForm.needExternalAccountField.label`) || getTranslation(`signUpForm.needExternalAccountField.label`),
      info: getTranslation(`${targetedKey}.signUpForm.needExternalAccountField.info`) || getTranslation(`signUpForm.needExternalAccountField.info`),
      error: getTranslation(`${targetedKey}.signUpForm.needExternalAccountField.error`) || getTranslation(`signUpForm.needExternalAccountField.error`),
    };
  }

  get externalAccountIdRule() {
    return (v) => !!v || this.needExternalAccountField.error;
  }

  get cardTooltipImageSrc() {
    return this.cardTooltip.imageSrc ? `${process.env.VUE_APP_API_URL}/api${this.cardTooltip.imageSrc}` : null;
  }

  get externalAccountLabel() {
    return getTranslation(`${this.platform.key}.signUpForm.externalAccountLabel`) || this.needExternalAccountField.label;
  }

  get errorDescription() {
    if (EXTERNAL_AND_DOB_EXCEPTIONS.includes(this.errorCode)) {
      const message =
        this.submittedForm && this.submittedForm.service.key === ServiceKey.TBM
          ? 'Date de naissance ou code client invalide.'
          : 'Date de naissance ou numéro client invalide.';
      return {
        dob: true,
        externalAccountId: true,
        message,
      };
    }

    if (EXTERNAL_EXCEPTIONS.includes(this.errorCode)) {
      return {
        dob: false,
        externalAccountId: true,
        message: 'Saisie invalide.',
      };
    }

    if (CLIENT_EXCEPTIONS.includes(this.errorCode)) {
      return {
        dob: true,
        externalAccountId: true,
        message: 'Mise à jour de votre compte impossible. Veuillez réessayer ultérieurement.',
      };
    }

    if (this.errorCode === IRIGO_USER_NOT_FOUND) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Compte A'Tout introuvable. Vérifiez les données saisies.`,
      };
    }

    if (this.errorCode === IRIGO_MTICKET_USER_NOT_FOUND) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Aucun compte M-Ticket n'est associé à votre compte Irigo.
          Les adresses mail de votre compte Irigo et M-Ticket doivent correspondre.`,
      };
    }

    if (this.errorCode === BIBUS_MTICKET_USER_NOT_FOUND) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Aucun compte M-Ticket n'est associé à votre compte Bibus.
          Les adresses mail de votre compte Bibus et M-Ticket doivent correspondre.`,
      };
    }

    if (this.errorCode === KORRIGO_USER_NOT_FOUND) {
      return {
        dob: true,
        externalAccountId: true,
        message: `Le service Korrigo n'a pu être associé. Vérifiez le numéro de carte et la date de naissance saisie.`,
      };
    }

    if (this.errorCode === EXTERNAL_API_DOWN) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Le service sélectionné est actuellement indisponible. Merci de réessayer ultérieurement.`,
      };
    }
    if (this.errorCode === CONFLICT_ASTUCE) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Un autre compte avec le même numéro client existe déjà.
            Un email contenant un lien permettant de finaliser la synchronisation des comptes va être envoyé sur cette adresse si vous cliquez sur le bouton ci-dessous.`,
        title: 'Numéro client déjà existant',
      };
    }

    if (this.errorCode === TBM_UNAUTHORIZED_EXCEPTION) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Votre session est expiré. Veuillez vous reconnecter à TBM`,
      };
    }

    if (this.errorCode === TBM_SVD_DIFFERENCE_DETECTED_EXCEPTION) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Ce code client ne correspond pas à vos informations personnelles.
        Veuillez vous rendre sur <a href="https://mytbm.infotbm.com/" target="_blank">votre compte TBM</a>
        pour les mettre à jour`,
      };
    }

    if (this.errorCode === TBM_SVD_CUSTOMER_NOT_FOUND) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Code client invalide`,
      };
    }
    if (
      [TBM_PAIRING_EXCEPTION, TBM_MTICKET_PAIRING_EXCEPTION, TBM_CUSTOMER_NOT_FOUND_EXCEPTION, TBM_CONFLICT_EXCEPTION, MODALIS_CONFLICT_EXCEPTION].includes(
        this.errorCode,
      )
    ) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Une erreur est survenue, merci de réessayer. Si le problème persiste, merci de contacter notre
          <a href="https://tbmfid.infotbm.com/help" target="_blank">service client</a> pour obtenir de l'aide.`,
      };
    }

    if (this.errorCode) {
      return {
        dob: false,
        externalAccountId: false,
        message: `Oops! Something went wrong [${this.errorCode}]`,
      };
    }

    return undefined;
  }

  get disableServiceSelection() {
    return !getUIConfig(this.platform.key, 'signup.enableMultipleRegister');
  }

  // ===== methods =======
  createAstuceFormServices(serviceId, externalAccountId, sortedServices) {
    const myAstuceForm = {
      serviceId,
    };

    const clubAstuceService = sortedServices.find((service) => service.key === ServiceKey.CLUB_ASTUCE);
    if (!clubAstuceService) {
      throw new Error('Bad astuce services configuration');
    }

    const clubAstuceForm = {
      serviceId: clubAstuceService.id,
      externalAccountId,
    };

    return [myAstuceForm, clubAstuceForm];
  }

  createFormServices(serviceId, externalAccountId) {
    return [
      {
        serviceId,
        externalAccountId,
      },
    ];
  }

  async submit() {
    if (!(this.$refs.form as any).validate()) {
      return undefined;
    }

    this.submitting = true;

    this.submittedForm = { ...this.form };

    const serviceId = (this.form.service as any).id;
    const externalAccountId = this.form.externalAccountId;

    const isAstuceCase = this.disableServiceSelection && externalAccountId;
    const formServices = isAstuceCase
      ? this.createAstuceFormServices(serviceId, externalAccountId, this.sortedServices)
      : this.createFormServices(serviceId, externalAccountId);

    try {
      this.errorCode = '';
      const createUserResponse = await createUser({
        services: formServices,
        platformUUID: this.platform.uuid,
        userInfos: this.userInfos,
        newsletter: this.form.newsletterIsAccepted,
        cguValidated: this.form.cguValidated,
        dateOfBirth: this.form.dateOfBirth,
        referrerCode: this.form.referrerCode,
        externalAccessToken: this.externalAccessToken,
      });

      return loginAndRedirectToLanding(createUserResponse.tokens);
    } catch (e) {
      this.userInput = {
        services: formServices,
        platformUUID: this.platform.uuid,
        userInfos: this.userInfos,
        newsletter: this.form.newsletterIsAccepted,
        cguValidated: this.form.cguValidated,
        dateOfBirth: this.form.dateOfBirth,
        referrerCode: this.form.referrerCode,
      };
      const axiosResponse = (e as any).response;
      if (axiosResponse) {
        this.errorCode = getApiErrorCode(e);
      } else {
        throw e;
      }
    } finally {
      this.submitting = false;
    }

    return undefined;
  }

  declineSynchro() {
    this.showDialogError = false;
    this.errorCode = '';
  }
}
