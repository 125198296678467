/* eslint-disable import/no-cycle */
import jwtDecode from 'jwt-decode';
import { AxiosResponse } from 'axios';
import { apiAuthenticatedInstance, apiPublicInstance } from '@/services/API';
import store from '@/store';
import { AnswerToSend, HttpStatus, LoginResponse, Question, UpdateProfileFormRules, User, UserCreationRequest, UserInfosForPatch } from './types';

import { getTranslation } from '@/i18n';
import { Service, UserService, UserServiceProduct } from '../merchantSpace/types';

const moment = require('moment');

moment.locale('fr');

export class AnswerNotPublishedOrAlreadyAnswered extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'AnswerNotPublishedOrAlreadyAnswered';
  }
}

export const formRules: UpdateProfileFormRules = {
  name: [(v) => !!v || 'Nom requis'],
  firstname: [(v) => !!v || 'Prénom requis'],
  email: [(v) => !!v || "L'email est requis", (v) => /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w*)+$/.test(v) || "L'email n'est pas au format valide"],
  password: [
    (v) => !!v || 'Mot de passe requis',
    (v) =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/.test(v) ||
      'Votre mot de passe doit être composé de 8 caractères minimum / une minuscule, une majuscule, un chiffre, un caractère spécial.',
  ],
  // SSO
  externalPassword: [(v) => !!v || 'Mot de passe requis'],
  confirmPassword: [(v) => !!v || 'Confirmation du mot de passe requis'],
  externalAccountId: [(v) => !!v || 'Numéro de compte requis'],
  gcuAndConfidentiality: [(v) => v === true || 'Veuillez accepter les CGU et la Données personnelles - RGPD'],
  gamesRegulation: [(v) => v === true || 'Veuillez accepter le Parcours utilisateurs'],
  subject: [(v) => !!v || 'Sujet requis'],
  description: [(v) => !!v || 'Description requise'],
  dateOfBirth: [
    (v) => !!v || 'Date de naissance requise',
    (v) => /\d{2}\/\d{2}\/\d{4}/.test(v) || 'Format non pris en compte - exemple valide: 20/10/1992',
    (v) => new Date(moment(v, 'DD/MM/YYYY').add(1, 'days')) < new Date() || 'Veuillez entrer une date de naissance valide',
    (v) => new Date(moment(v, 'DD/MM/YYYY').add(1, 'days')) > new Date('01/01/1900') || 'Veuillez entrer une date de naissance valide',
  ],
  optionalDateOfBirth: [
    function isValidDateOfBirth(v) {
      if (v !== undefined) {
        if (/(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).([19]{2})?(19[0-9]{2}|20[0-9]{2})/.test(v) === false) {
          return 'Veuillez entrer une date de naissance valide';
        }
        if (new Date(moment(v, 'DD/MM/YYYY').add(1, 'days')) > new Date()) {
          return 'Veuillez entrer une date de naissance valide';
        }
        if (new Date(moment(v, 'DD/MM/YYYY').add(1, 'days')) < new Date('01/01/1900')) {
          return 'Veuillez entrer une date de naissance valide';
        }
      }
      return true;
    },
  ],
  requestTypeId: [(v) => !!v || 'Catégorie requise'],
  minorConsent: [(v) => v === true || 'Veuillez accepter le recueil de vos consentements'],
  parentalAgreement: [(v) => v === true || 'Veuillez accepter le recueil de vos consentements'],
};

export function createExternalAccountIdRule(externalAccountIdRegex: RegExp | undefined) {
  if (!externalAccountIdRegex) {
    return formRules.externalAccountId;
  }

  const rule = (v) => new RegExp(externalAccountIdRegex).test(v) || 'Numéro de compte invalide';
  return [...formRules.externalAccountId, rule];
}

export interface PasswordUpdateRequest {
  oldPassword: string;
  newPassword: string;
}

export const createUser = async (userCreationRequest: UserCreationRequest, locale: string): Promise<User> => {
  try {
    return await apiPublicInstance()
      .post('/api/users', {
        ...userCreationRequest,
        platformUuid: process.env.VUE_APP_PLATFORM_UUID,
        locale,
      })
      .then((response) => response.data);
  } catch (error) {
    throw error;
  }
};

export const signinUser = async (
  email: string,
  password: string,
): Promise<
  AxiosResponse<
    LoginResponse & {
      migrated: boolean;
    }
  >
> =>
  apiPublicInstance().post(`/api/userAuth/login?platformUuid=${process.env.VUE_APP_PLATFORM_UUID}`, {
    email,
    password,
  });

export const getCurrentUser = async (refresh = false): Promise<User> => {
  // On regarde si on a déjà un user dans le store
  const storedUser = await store.getters['user/getUser'];
  if (storedUser && !refresh) {
    return storedUser;
  }
  // Si pas de user ou besoin de refresh, on va chercher le user sur l'API
  const user = await fetchCurrentUser();
  store.dispatch('user/setUser', user);
  return store.getters['user/getUser'];
};

export async function getUserProfile(): Promise<{
  userInfosForPatch: UserInfosForPatch;
  additionalInfos: {
    id: number;
    emailAsExternal: boolean;
    activeUS?: UserService;
    activeUSP?: UserServiceProduct;
    emailConfirmation: boolean;
  };
}> {
  let emailAsExternal = false;
  let externalAccountId: string = '';
  let activeUSP: UserServiceProduct | undefined;

  const { id, emailConfirmation, email, lastname, firstname, phoneNumber, deliveryAdress, zipCode, city, newsletter, dateOfBirth, userServices } =
    await refreshUser();

  const activeUS = findActiveUserService(userServices);

  if (activeUS) {
    emailAsExternal = activeUS.service.emailAsExternal;
    activeUSP = findActiveUserServiceProduct(activeUS.userServiceProducts);
    externalAccountId = getExternalAccountId(emailAsExternal, activeUS, activeUSP);
  }

  const userInfosForPatch = {
    email,
    lastname,
    firstname,
    phoneNumber,
    deliveryAdress,
    zipCode,
    city,
    newsletter,
    dateOfBirth,
    externalAccountId,
  };

  const additionalInfos = {
    id,
    emailAsExternal,
    activeUS,
    emailConfirmation: emailConfirmation || false,
    activeUSP,
  };

  return {
    userInfosForPatch,
    additionalInfos,
  };
}

export function getExternalAccountId(emailAsExternal: boolean, activUS: UserService, activeUSP?: UserServiceProduct): string {
  let externalAccountId: string;

  if (emailAsExternal) {
    externalAccountId = activUS.externalAccountEmail;
  } else {
    externalAccountId = activeUSP ? activeUSP.productCode : '';
  }

  return externalAccountId;
}

export const findActiveUserService = (userServices: UserService[]) => findActive<UserService>(userServices);
export const findActiveUserServiceProduct = (userServiceProducts: UserServiceProduct[]) => findActive<UserServiceProduct>(userServiceProducts);

export function findActive<
  T extends {
    revoked: boolean;
  },
>(data: T[]): T | undefined {
  return data.find(({ revoked }) => !revoked);
}

export const refreshUser = getCurrentUser.bind(undefined, true);

// currentUserRequest prevent to send the same request
// multiple times over a similar interval
let currentUserRequest;

export function fetchCurrentUser() {
  if (!currentUserRequest) {
    currentUserRequest = apiAuthenticatedInstance()
      .get(`/api/users/current?platformUuid=${process.env.VUE_APP_PLATFORM_UUID}`)
      .then(({ data }) => data)
      .finally(() => {
        currentUserRequest = undefined;
      });
  }

  return currentUserRequest;
}

export const updatePassword = async (id: number, passwords: PasswordUpdateRequest): Promise<User> => {
  try {
    return await apiAuthenticatedInstance().patch(`/api/users/${id}`, passwords);
  } catch (e) {
    throw e;
  }
};

export const confirmEmail = async (token: string, platformUuid: string, fromAdmin: boolean): Promise<User> => {
  return (await apiPublicInstance().put(`/api/users/emailConfirmation/${token}?fromAdmin=${fromAdmin}`, { platformUuid })).data;
};

export const login = async (email: string, password: string): Promise<Boolean | null> => {
  const res = await signinUser(email.toLowerCase(), password);
  if (res.data.migrated) {
    return true;
  }

  if (res.status === HttpStatus.CREATED) {
    await store.dispatch('user/login', { tokens: res.data.tokens });
  }
  return null;
};

export const resetAccessToken = async () => {
  await store.dispatch('user/resetAccessToken');
};

export const getUserEmail = async () => {
  const token = store.getters['user/getAccessToken'];
  const { email } = jwtDecode(token);
  return email;
};

export const getUserId = async () => {
  const token = store.getters['user/getAccessToken'];
  const { id } = jwtDecode(token);
  return id;
};

export const getUserReferralCode = async () => {
  const token = store.getters['user/getAccessToken'];
  const { referralCode } = jwtDecode(token);
  return referralCode;
};

export const updateUserFields = (id: number, updateUserField: UserInfosForPatch, locale: string) => {
  return apiAuthenticatedInstance().patch(`/api/users/${id}`, { ...updateUserField, locale });
};

export const getUserPoints = async (targetAudienceId: number) => {
  const userPoints = await apiAuthenticatedInstance()
    .get(`/api/users/points?platformUuid=${process.env.VUE_APP_PLATFORM_UUID}&targetAudience=${targetAudienceId}`)
    .then((res) => res.data)
    .catch((err) => console.warn(err));
  await store.dispatch('user/setUserPoints', userPoints);
  return store.getters['user/getUserPoints'];
};

export const getUserPeremptionBalance = async (nextPeremptionDate: Date) => {
  const userPeremptionBalance = await apiAuthenticatedInstance()
    .get(`/api/users/points/peremption`, {
      params: {
        nextPeremptionDate: nextPeremptionDate.toISOString(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.warn(err));
  let expiredPointCount = 0;
  if (userPeremptionBalance && userPeremptionBalance.expiredPointCount) {
    expiredPointCount = userPeremptionBalance.expiredPointCount;
  }
  await store.dispatch('user/setUserPeremptionBalance', expiredPointCount);
  return store.getters['user/getUserPeremptionBalance'];
};

export const refreshUserPoints = getUserPoints.bind(undefined, 1);

export const getHistory = async (page: number, limit: number) => {
  return (await apiAuthenticatedInstance().get(`/api/users/history?page=${page}&limit=${limit}`)).data;
};

export const requestPasswordReset = async (email: string, baseUrl?: string, migrated?: Boolean, locale: string = 'fr') => {
  try {
    const config = {
      headers: {
        'X-Referer': baseUrl,
      },
    };
    const response = await apiPublicInstance().post(
      `/api/users/auth/forgot-password`,
      { email, locale, platformUuid: process.env.VUE_APP_PLATFORM_UUID, migrated },
      config,
    );
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }

    return { status: HttpStatus.SERVICE_UNAVAILABLE, data: 'Service not available' };
  }
};

export const resetPassword = async (
  password: string,
  params: {
    email: string;
    token: string;
    platformUUID: string;
  },
  locale: string = 'fr',
) => {
  try {
    const response = await apiPublicInstance().post(`/api/users/auth/reset-password`, { password }, { params });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }

    return { status: HttpStatus.SERVICE_UNAVAILABLE, data: 'Service not available' };
  }
};

export const deactivateUser = async (id: number, reason: string, locale: string) => {
  const platformUuid = process.env.VUE_APP_PLATFORM_UUID;
  return (
    await apiAuthenticatedInstance().put(`/api/users/${id}/deactivate`, {
      reason,
      locale,
      platformUuid,
    })
  ).data;
};

export const reactivateUser = async (id: number) => {
  return (await apiAuthenticatedInstance().put(`/api/users/${id}/reactivate`)).data;
};

export const checkCguValidity = async () => {
  return (await apiAuthenticatedInstance().get(`/api/users/checkCguValidity?platformUuid=${process.env.VUE_APP_PLATFORM_UUID}`)).data;
};

export const userFirstConnection = async () => {
  return (await apiAuthenticatedInstance().get(`/api/users/userFirstConnection`)).data;
};

export const signNewCgu = async (version: string) => {
  return (
    await apiAuthenticatedInstance().post(`/api/users/cgu/signNewCgu`, {
      platformUuid: process.env.VUE_APP_PLATFORM_UUID,
      version,
    })
  ).data;
};

export const applySSOSynchro = async (userInfos: string, expires: string, signature: string, platformUUID: string, basedOn: string, cryptedPart: string) => {
  return (
    await apiPublicInstance().post(`api/users/synchronize`, {
      userInfos,
      expires,
      signature,
      platformUUID,
      basedOn,
      cryptedPart,
    })
  ).data;
};

export const autoApplySSOSynchro = async (userInfos: string, platformUUID: string, cryptedPart: string) => {
  return (
    await apiPublicInstance().post(`api/users/auto-synchronize`, {
      userInfos,
      platformUUID,
      cryptedPart,
      basedOn: 'based_on_association',
    })
  ).data;
};

export async function sendConfirmationEmail(locale) {
  return apiAuthenticatedInstance().post(`api/users/current/sendConfirmationEmail`, { locale });
}

export const getPublishedQuestion = async (): Promise<Question | null> => {
  return (await apiAuthenticatedInstance().get(`/api/questions/publishedOn/${process.env.VUE_APP_PLATFORM_UUID}`)).data;
};

export const answerQuestion = async (answer: AnswerToSend): Promise<number> => {
  const response = (await apiAuthenticatedInstance().post(`/api/questions/answer/${process.env.VUE_APP_PLATFORM_UUID}`, answer)) as any;
  if (response.isAxiosError) {
    throw new AnswerNotPublishedOrAlreadyAnswered();
  }
  return response.data.points;
};

export const getUserServices = async (): Promise<
  Array<{
    service: Service;
    actualProduct: UserServiceProduct;
  }>
> => (await apiAuthenticatedInstance().get(`/api/users/my/services`)).data;

// RETURN TYPE ActionCredit
export const getEmailValidationPoints = async (): Promise<any> => (await apiAuthenticatedInstance().get(`/api/users/my/register-action/points`)).data;

export const synchronizeApplication = async (
  service: Service,
  synchronisationKeyName: 'email' | 'externalAccountId',
  synchronizationKey: string,
  locale = 'fr',
): Promise<Service | any> => {
  const dto = {
    service,
    serviceSynchronisationKey: synchronisationKeyName,
    locale,
  };
  dto[synchronisationKeyName] = synchronizationKey;
  return (await apiAuthenticatedInstance().post(`/api/users/my/service`, dto)).data;
};

export const sendSSOSyncEmail = async (userInfos: string): Promise<Service | any> => {
  return (await apiPublicInstance().post(`/api/users/sync-email`, userInfos)).data;
};

export const unsynchronizeApplication = async (service: Service): Promise<Service | any> => {
  try {
    return (await apiAuthenticatedInstance().delete(`/api/users/my/service/${service.id}`)).data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }

    return { status: HttpStatus.SERVICE_UNAVAILABLE, data: 'Service not available' };
  }
};

export const validateToken = async (
  token: string,
): Promise<{
  applicationName?: string;
  success: boolean;
  error?: any;
}> => {
  try {
    return (await apiPublicInstance().post(`/api/users/validateSynchronisation/${token}`)).data;
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        error: error.response.data,
      };
    }
    return { success: false, error: 'Service not available' };
  }
};

export function getAppSyncText(serviceKey: string) {
  return getTranslation(`${serviceKey}.synchronization`);
}

export function getNextPeremptionDate(peremptionFunctionality) {
  const expirationDay = peremptionFunctionality.expirationDay;
  if(!expirationDay){
    return null
  }

  const today = new Date();
  let year = today.getFullYear();
  const day = today.getDate();
  let month = today.getMonth(); // Mois suivant (0 = janvier, donc +1)

  if (day >= expirationDay){
    month = today.getMonth() + 1;
  }

  // Si le mois est décembre, passer à janvier de l'année suivante
  if (month > 11) {
    month = 0;
    year += 1;
  }

  const expirationDate = new Date(year, month, expirationDay);

  return expirationDate;
}
