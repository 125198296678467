

















































































































































import { Component, Vue } from 'vue-property-decorator';
import ProfileBanner from '@/components/Account/ProfileBanner.vue';
import PageTitle from '@/components/PageTitle.vue';
import * as UserService from '@/services/userSpace/UserService';
import { isApplicationType } from '@/services/merchantSpace/PlatformService';
import { User } from '@/services/userSpace/types';
import { PlatformKey } from '@/constants';
import { Service, UserServiceProduct } from '@/services/merchantSpace/types';
import { getPlatformServices } from '@/api/resources/platformApi';
import { getCashbackTransactions, Transaction } from '@/api/resources/orderApi';

@Component({
  components: {
    ProfileBanner,
    PageTitle,
  },
})
export default class Profile extends Vue {
  user: User = {
    id: -1,
    email: '',
    password: '',
    externalAccountId: '',
    firstname: '',
    lastname: '',
    userServices: [],
  };

  platformServices: Service[] = [];

  userServicesWithProduct: Array<{ service: Service; actualProduct: UserServiceProduct }> = [];

  transactions: Transaction[] = [];

  async mounted() {
    this.user = await UserService.getCurrentUser();
    this.platformServices = await getPlatformServices();
    this.userServicesWithProduct = await UserService.getUserServices();
    this.transactions = await getCashbackTransactions(`${process.env.VUE_APP_PLATFORM_UUID}_${this.user.id}`);
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get isThereAppToSynchronize() {
    return this.platformServices.filter((el) => isApplicationType(el.typeId)).length > 0;
  }

  get showApplicationsItem() {
    return ![PlatformKey.TBM, PlatformKey.IRIGO].includes(this.platform.key);
  }

  get userServices() {
    return this.userServicesWithProduct.map((el) => el.service);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get compShowCashback() {
    return this.$store.getters['platform/getPlatform'].cashbackOffers;
  }

  get compIsThereTransactions() {
    return this.transactions.length > 0;
  }

  get isReadOnly() {
    return this.$store.getters['user/isReadOnly'];
  }

  get isSSOEnabled () {
    return this.$store.getters['platform/isSSOEnabled'];
  }

  get showPasswordLink() {
    return !this.isReadOnly && !this.isSSOEnabled;
  }

  navigateToPassword() {
    this.$router.push({ name: 'update-password' });
  }

  navigateToHelp() {
    this.$router.push({ name: 'help' });
  }

  navigateToOrders(): void {
    this.$router.push({ name: 'orders' });
  }

  navigateToCashback(): void {
    this.$router.push({ name: 'cashback' });
  }

  navigateToDocumentation(): void {
    this.$router.push({ name: 'documentation' });
  }

  navigateToApplications(): void {
    this.$router.push({ name: 'applications' });
  }

  navigateToPointsHistory() {
    this.$router.push({ name: 'history' });
  }

  navigateToPointsScale() {
    this.$router.push({ name: 'scale-points-public' });
  }
}
