


















































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import PageTitle from '@/components/PageTitle.vue';
import CalendarCard from '@/components/Calendar/CalendarCard.vue';
import { Calendar, CalendarDay, findMyCalendar, findRunningPublicCalendar, openDay } from '@/services/merchantSpace/CalendarService';
import CalendarOpenDay from '@/components/Dialogs/CalendarOpenDay.vue';
import { flashDanger } from '@/store';
import { loadFontFace } from '@/utils/loadFontFace';

type AnimationState = 'idle' | 'discovering' | 'discovering-reverse';

@Component({
  components: {
    PageTitle,
    CalendarCard,
    CalendarOpenDay,
  },
})
export default class CalendarView extends Vue {
  isLoading = false;

  calendar: Calendar | null = null;

  discoveredDay: CalendarDay | null | undefined = null;

  animationState: AnimationState = 'idle'

  showDiscoveredDialog = false;

  loadingDays = {};

  // seulement utile pour les jours sans offre (ne devrait pas arriver - mais sait on jamais)
  discoveredCards = {};

  errorMessage = '';

  animTimeout: any = null;

  async created() {
    this.isLoading = true;
    try {
      await this.loadCalendar();

      const hasForbiddenAccess = !this.isConnected && !this.isPublicCalendarActive;
      if (!this.calendar || hasForbiddenAccess) {
        return this.$router.replace({ name: 'home' });
      }

      const { titleFont, textFont } = this.calendar.style;
      await Promise.all([
        titleFont.url ? loadFontFace('TitleCalendarPreview', titleFont.url) : undefined,
        textFont.url ? loadFontFace('TextCalendarPreview', textFont.url) : undefined
      ]);
    } catch (e) {
      this.errorMessage = 'Oops, une erreur est survenue';

      setTimeout(() => {
        this.$router.replace('/');
      }, 4000);
    } finally {
      this.isLoading = false;
    }

    return undefined;
  }

  @Watch('discoveredDay')
  onDiscoveredDay (currentDiscoveredDay: CalendarDay  | null, previousDiscoveredDay: CalendarDay | null) {
    const discoveredOffer = previousDiscoveredDay && previousDiscoveredDay.offer;

    if (discoveredOffer) {
      // L'offre a été vu et validé.
      // On peut la flagger comme tel
      const availableAt = dayjs.utc(previousDiscoveredDay!.availableAt, 'YYYY-MM-DD').toDate();
      const positionId = DateId(availableAt);
      this.$set(this.loadingDays, positionId, false);
    }

    const hasAnimation = shouldAnimate(currentDiscoveredDay) || shouldAnimate(previousDiscoveredDay);
    if (hasAnimation) {
      const toAnimState: AnimationState = shouldAnimate(currentDiscoveredDay) ? 'discovering' : 'discovering-reverse';
      this.animateDiscover(toAnimState)
    }
  }

  animateDiscover (nextState: AnimationState) {
    this.animationState = nextState;

    if (this.animTimeout) {
      clearTimeout(this.animTimeout);
      this.animTimeout = null;
    }

    if (nextState === 'discovering') {
      this.animTimeout = setTimeout(() => {
        this.showDiscoveredDialog = true;
      }, 600);
    } else {
      this.showDiscoveredDialog = false;
    }
  }

  get giftList () {
    if (!this.calendar) {
      return [];
    }

    return Array.from({ length: this.dayCount }, (_, index) => {
      const startingAt = dayjs.utc((this.calendar as any).startAt, 'YYYY-MM-DD');
      const giftDay = startingAt.add(index, 'day').toDate();
      const lockLabel = giftDay
        .getUTCDate()
        .toString()
        .padStart(2, '0');

      const dayId = DateId(giftDay);
      const calendarDay = this.calendarOfferByDay[dayId];
      const isOpen = calendarDay && calendarDay.openedDayList ? Boolean(calendarDay.openedDayList.length) : Boolean(this.discoveredCards[dayId]);
      const isLoading = !!(calendarDay && this.loadingDays[dayId]);

      return {
        positionId: dayId,
        lockLabel,
        date: giftDay,
        isSelectionable: Date.now() > giftDay.getTime(),
        calendarDay,
        isOpen,
        isLoading
      }
    });
  }

  get calendarOfferByDay () {
    if (!this.calendar) {
      return {};
    }

    return this.calendar.calendarDays.reduce((acc, calendarDay) => {
      const availableAt = dayjs.utc(calendarDay.availableAt, 'YYYY-MM-DD').toDate();
      const dayId = DateId(availableAt);
      acc[dayId] = calendarDay;

      return acc;
    }, {});
  }

  get rootCSS(): any {
    if (!this.calendar) {
      return {};
    }

    const { style } = this.calendar;
    const hasBackgroundUrl = !!style.backgroundUrl;

    return {
      color: style.textColor,
      '--calendar-main-color': style.textColor,
      ...hasBackgroundUrl && {
        'background-image': `url(${style.backgroundUrl})`,
      },
      ...!hasBackgroundUrl && {
        'background-color': 'black'
      }
    };
  }

  get dayCount(): number {
    if(!this.calendar) {
      return 0;
    }

    return getTotalDayCount(this.calendar.startAt, this.calendar.endAt);
  }

  get isConnected(): boolean {
    return this.$store.getters['user/isConnected'];
  }

  get platformId() {
    return this.$store.getters['platform/getPlatform'].id;
  }

  get isPublicCalendarActive() {
    return this.$store.getters['platform/isPublicCalendarActive'];
  }

  handleOpenDialogVisibility({ isVisible }) {
    if (!isVisible) {
      this.discoveredDay = null;
    }
  }

  async loadCalendar () {
    this.calendar = this.isConnected
      ? await findMyCalendar()
      : await findRunningPublicCalendar({ platformId: this.platformId });
  }

  async openGift(positionId: string, calendarDay?: CalendarDay) {
    if (!this.calendar) {
      return;
    }

    const isOpen = !!(calendarDay && calendarDay.openedDayList && calendarDay.openedDayList.length);
    if (this.isConnected && calendarDay && !isOpen) {
      // this stuff should be handle at store level or in the calendarcard component

      this.$set(this.loadingDays, positionId, true);

      const openDayResult = await openDay(this.calendar.id, {
        calendarDayId: calendarDay.id
      })
      .catch(e => {
        console.error('Open day failed with', e);
        return e;
      });

      const hasFailed = openDayResult instanceof Error;
      if (hasFailed) {
        flashDanger({ message: 'Oops, une erreur est survenue' });
      } else {
        await this.loadCalendar();
      }
    }

    this.discoveredDay = calendarDay;

    this.$set(this.discoveredCards, positionId, true);
  }
}

function shouldAnimate(calendarDay?: CalendarDay | null) {
  return calendarDay && calendarDay.offer
}

function getTotalDayCount(startAt, endAt) {
  const start = dayjs(startAt);
  const end = dayjs(endAt);

  return end.diff(start, 'days') + 1;
}

function DateId(date: Date): string {
  return `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}`;
}
