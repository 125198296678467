var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offer-details" },
    [
      _c(
        "div",
        { staticClass: "offer-details-wrapper" },
        [
          _c(
            "div",
            { staticClass: "offer-details-photos" },
            [
              _c("CarouselCard", {
                staticClass: "offer-details-carousel",
                attrs: { pictures: _vm.compPreviewsPhoto }
              }),
              _vm.isLuckOffer
                ? _c("Countdown", {
                    staticClass: "offer-details-countdown",
                    attrs: { drawAt: _vm.offer.drawAt }
                  })
                : _vm._e(),
              _vm.isLuckOffer
                ? _c("OrdersNumber", {
                    staticClass: "offer-details-orders-number",
                    attrs: {
                      offerId: _vm.offer.id,
                      "platform-id": _vm.platformId
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("OfferInfo", {
            staticClass: "offer-details-points",
            attrs: {
              title: _vm.offer.title,
              companyName: _vm.offer.companyName,
              points: _vm.offer.points,
              maxPoints: _vm.offer.maxPoints,
              filledPoints: _vm.offer.filledPoints || 0,
              typeId: _vm.offer.offerTypeId,
              targetAudienceId: _vm.offer.targetAudienceId
            }
          }),
          _c("OfferDescription", {
            staticClass: "offer-details-description",
            attrs: { description: _vm.offer.description }
          }),
          _c("OfferDelivery", {
            staticClass: "offer-details-delivery",
            attrs: {
              websiteURL: _vm.offer.site,
              typeId: _vm.offer.offerTypeId,
              currentStock: _vm.offer.currentStock,
              deliveryMethodId: _vm.offer.deliveryMethodId,
              collectionDateOption: _vm.offer.collectionDateOption,
              drawAt: _vm.offer.drawAt,
              startedAt: _vm.offer.startedAt,
              expiredAt: _vm.offer.expiredAt,
              collectionDateUnique: _vm.offer.collectionDateUnique,
              collectionDateMin: _vm.offer.collectionDateMin
            }
          })
        ],
        1
      ),
      _vm.isConnected
        ? _c(
            "v-btn",
            {
              staticClass: "offer-details-btn",
              attrs: { large: "", rounded: "", color: "shopoffre_fond_bouton" },
              on: { click: _vm.onBuyClick }
            },
            [_vm._v(" " + _vm._s(_vm.shopBtnLabel) + " ")]
          )
        : _c(
            "SignInButton",
            {
              staticClass: "offer-details-btn",
              attrs: {
                forceRedirectTo: {
                  name: "offerDetail",
                  params: {
                    offerId: _vm.offer && _vm.offer.id
                  }
                },
                large: "",
                rounded: "",
                color: "shopoffre_fond_bouton"
              }
            },
            [_vm._v(" " + _vm._s(_vm.shopBtnLabel) + " ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }